//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangePasswordDialogLayout from '@/frontend/components/common/change-password-dialog-layout.vue';
import {mapState, mapActions} from 'vuex'
export default {
  name: "header",
  components: {
    ChangePasswordDialogLayout
  },
  data() {
    return {
      changePasswordDialog: false,
      icon: undefined,
      menuItems: [],
      mobileMenuOpen: false
    }
  },
  computed: {
    ...mapState('shoppingCart', ['cartItemCount']),
    isChangePasswordRequired() {
      return this.$auth?.loggedIn && this.$auth?.user?.mustChangePasswordOnNextLogin
    },
    currentRoute() {
      return this.$route.path
    },
  },
  mounted(){
    this.getHeader()
    this.frontendGetBySessionId()
    if(this.$auth.loggedIn && this.$auth.user)
      this.changePasswordDialog = this.isChangePasswordRequired
  },
  methods: {
    ...mapActions('shoppingCart', ['frontendGetBySessionId']),
    toggleChildrenVisibility(item) {
      item.childrenVisible = item.childrenVisible === undefined ? true : !item.childrenVisible
      this.$forceUpdate() //sry but had to do it
    },
    async getHeader() {
      const headerData = (await this.$axios.get(`/contents/header`)).data

      this.icon = headerData?.details?.icon
      this.menuItems = headerData?.details?.menu_items
    },
  },
}
