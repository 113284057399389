//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

export default {
  name: "change-password-dialog-layout",
  props: {
    mustChangePassword: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      changePasswordForm: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      valid: false,
      passwordRegexRules: {
        errorMessage: "Password must be at least 5 characters long!",
        regex: '^.{5,}$',
      },
    }
  },
  async beforeMount() {
    this.passwordRegexRules = (await this.$axios.$get('SecuritySettings/password-regex'));
  },
  methods: {
    ...mapActions('common/userProfile', ['changePassword']),
    passwordRegexRule(v) {
      const regExp = new RegExp(this.passwordRegexRules.regex);
      return regExp.test(v) || this.passwordRegexRules.errorMessage
    },
    async changePasswordButtonClicked() {
      this.$refs.changePasswordForm.validate();
      if (this.valid) {
        const response = await this.changePassword({
          passwordInfo: this.changePasswordForm,
        })
        if (
            response &&
            response.apiMessages.hasErrors &&
            response.apiMessages.validationErrors
        ) {
          this.$toast.error(response.apiMessages.validationErrors.error)
        } else if (response) {
          this.$toast.success('Password successfully changed')
          this.setUserMustChangePassword()
          this.close()
        }
      }
    },
    setUserMustChangePassword() {
      const userToUpdate = {...this.$auth.user}
      userToUpdate.mustChangePasswordOnNextLogin = false
      this.$auth.setUser(userToUpdate)
    },
    close() {
      this.$emit('close')
    },
  },
  watch: {
    'changePasswordForm.newPassword'() {
      this.$refs.changePasswordForm.validate()
    },
  }
}
